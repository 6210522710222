// Generated by Framer (2372734)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lzrOFROwB", "xy4XhK5kF"];

const variantClassNames = {lzrOFROwB: "framer-v-lf5uk7", xy4XhK5kF: "framer-v-sh0m71"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "lzrOFROwB", "Variant 2": "xy4XhK5kF"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, sOi7rELHC: title ?? props.sOi7rELHC ?? "About", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lzrOFROwB"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, sOi7rELHC, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lzrOFROwB", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isDisplayed = () => {
if (baseVariant === "xy4XhK5kF") return true
return false
}

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-5KcXL", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-lf5uk7", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"lzrOFROwB"} ref={ref} style={{...style}} {...addPropertyOverrides({xy4XhK5kF: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>About</motion.p></React.Fragment>} className={"framer-1dgm4w5"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"qLKjCE7HN"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={sOi7rELHC} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNTAw", "--framer-font-weight": "500", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv)"}}>About</motion.p></React.Fragment>} className={"framer-lmeqk9"} fonts={["GF;Inter-500"]} layoutDependency={layoutDependency} layoutId={"KsYngDdRQ"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={sOi7rELHC} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/>)}</motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-5KcXL [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5KcXL .framer-1shfdnp { display: block; }", ".framer-5KcXL .framer-lf5uk7 { height: 24px; overflow: visible; position: relative; width: 46px; }", ".framer-5KcXL .framer-1dgm4w5, .framer-5KcXL .framer-lmeqk9 { flex: none; height: auto; left: 50%; overflow: visible; position: absolute; top: 50%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 46
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"xy4XhK5kF":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"sOi7rELHC":"title"}
 */
const FramerHxcUNHhOP: React.ComponentType<Props> = withCSS(Component, css, "framer-5KcXL") as typeof Component;
export default FramerHxcUNHhOP;

FramerHxcUNHhOP.displayName = "Nav";

FramerHxcUNHhOP.defaultProps = {height: 24, width: 46};

addPropertyControls(FramerHxcUNHhOP, {variant: {options: ["lzrOFROwB", "xy4XhK5kF"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, sOi7rELHC: {defaultValue: "About", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerHxcUNHhOP, [{family: "Inter", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/HxcUNHhOP:default", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf", weight: "500"}])